<!-- 



<div class="container3"> 
    <img alt='image' src="/assets/images_new/softLightCaseStudy.svg" *ngIf="bgLight" class="light1" >
    <img alt='image' src="/assets/images_new/darkLightCaseStudy.svg" *ngIf="bgLight" class="light2" >
    <div class="overlay2">
            <div class="left1">
                <h3>
                    <img alt='image' src="/assets/images_new/lineCS.svg" width="50" >
                    Product Development
                </h3>
                <h1>{{ sliderData[index]?.title }}</h1>
            </div>
            
            <div class="right1">
                {{ sliderData[index]?.description }}
            </div>
    </div>
    <div #slides>
        <ngx-slick-carousel class="carousel sliderx " 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig" 
                        (init)="slickInit($event)"
                        (breakpoint)="breakpoint($event)"
                        (afterChange)="afterChange($event)"
                            (beforeChange)="beforeChange($event)">
            <div ngxSlickItem *ngFor="let slide of sliderData" class="slide">
                <div class="slide2demo container">
                    <div class="multiBox" (click)="openCS(slide)">
                        <div class="ml ml1" [ngStyle]="{backgroundColor: bgColor}"></div>
                        <div class="ml ml2" [ngStyle]="{backgroundColor: bgColor}"></div>
                        <div class="ml ml3" [ngStyle]="{backgroundColor: bgColor}"></div>
                        <div class="ml ml4" [ngStyle]="{backgroundColor: bgColor}">
                            <img alt='image' [src]="slide?.image" >
                        </div>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>

        <button class="slickCaseStudyBtn1" *ngIf="index!=0" style="display: none;" (click)="slickModal.slickGoTo(index-1)"></button>
        <button class="slickCaseStudyBtn2" *ngIf="index!=(sliderData.length-1)" style="display: none;" (click)="slickModal.slickGoTo(index+1)"></button>
    
    </div>
</div>
<div class="sliderx" #slides>
    <div class="sliders" *ngFor="let sd of sliderData;let i=index">
        <div class="slide2demo container">

            <div class="multiBox">
                <div class="ml ml1" [ngStyle]="{backgroundColor: bgColor}"></div>
                <div class="ml ml2" [ngStyle]="{backgroundColor: bgColor}"></div>
                <div class="ml ml3" [ngStyle]="{backgroundColor: bgColor}"></div>
                <div class="ml ml4" [ngStyle]="{backgroundColor: bgColor}">
                    <img alt='image' [src]="sd?.image" >
                </div>
            </div>
        </div>
    </div>
</div>  -->










<div class="slides" *ngIf="sliderData" #slider> 

    <div
        *ngFor="let sd of sliderData;let i=index" 
        class="caseStudy" 
        [ngStyle]="
            {
                background: 'url(' + sd?.image + ')',
                position: 'absolute', 
                left: calculateSlidePosition(sd.slideIndex),
                color: colorText,
                minWidth: slideWidth+slideWidthUnit
            }" 
        [ngClass]="i==activeSlide?'active':(i-1==activeSlide)?'actRight':(i+1==activeSlide)?'actLeft':''"
        (click)="setActiveSlide(sd,i);"
    >
        <!-- <h1 class="text1">{{sd?.title.slice(0,sd?.title.length/2)}}</h1>
        <h1 class="text2">{{sd?.title.slice(sd?.title.length/2)}}</h1> -->
        <div [ngClass]="i==activeSlide?'':'overlay'"></div>
    </div>
</div>

