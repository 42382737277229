<div class="main ">
    <div class="s1 container2">
        <div class="banner">
            <img alt='image' src="/assets/images_new/case-studies/scottedil/s1.svg" >
        </div>
        <div class="text2">
            <p>
                A pharmacetical app made to simplify ease of buying 
                medicines in just three clicks. The best in the 
                industry. It is an American multinational 
                pharmaceutical and biotechnology corporation
                headquartered on 42 Street in Manhattan, New 
                York City. Established in 1849 in New York by two 
                German immigrants Charles Pfizer and his cousin 
                Charles E Erhart.
            </p>
        </div>
    </div>

    <div class="s2 container2">
        <div class="row">
            <div class="col-md-6 left">
                <h2>
                    SERVICES
                </h2>
                <div class="listItems">Research</div>
                <div class="listItems">Strategy</div>
                <div class="listItems">Planning</div>
                <div class="listItems">Ideation</div>
                <div class="listItems">Design</div>
                <div class="listItems">Art Direction</div>
                <div class="listItems">Development</div>
                <div class="listItems">Technology</div>
            </div>
            <div class="col-md-6 right">
                <h2>About</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
                
                <h2>Problem</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>

                <h2>Solution</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
            </div>
        </div>
    </div>

    <div class="s3 container2">
        <div class="s31">
            <div class="s311">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s2.svg" >                
            </div>
        </div>
        <div>
            <img alt='image' src="/assets/images_new/case-studies/scottedil/s3.png" >
        </div>
    </div>

    <div class="s4 container2">
        <div class="row">
            <div class="col-12">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s51.svg" >
            </div>
            <div class="col-sm-6">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s4.svg" >
            </div>
            <div class="col-sm-6 ">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s5.svg" >
            </div>
            
            <div class="col-12">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s6.svg" >
            </div>
            <img alt='image' class="bg" src="/assets/images_new/case-studies/scottedil/sb1.png" >
        
        </div>    
    </div>

    <div class="s5 container2">
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s7.svg" class="c51" >
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s7.png" class="c52" >
    </div>

    <div class="c6">
        <div class="container2">
            <div class="row">
                <div class="col-md-6 left" id="sticky">
                    <h2>
                        High Fidelity Wireframes
                        <img alt='image' src="/assets/images_new/case-studies/scottedil/magnifire.svg" class="c62" >
                    </h2>
                    <p>
                        A pharmacetical app made to simplify ease of buying  
                        medicines in just three clicks. The best in the industry. 
                        It is an is an American multinational pharmaceutical and 
                        biotechnology corporation headquartered on 42nd Street in 
                        Manhattan, New York City. Established in 1849 in New York 
                        by two German immigrants 
                        Charles Pfizer and his cousin Charles F. Erhart.
                    </p>
                </div>
            </div>
        </div>
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s8.png" class="c52" >
    </div>

    <div class="c7">
        <div class="container2">
            <div class="row c71">
                <div class="col-md-6 left">
                    <img alt='image' src="/assets/images_new/case-studies/scottedil/s9.png" >
                </div>
                <div class="col-md-6 right">
                    <h2>Pharmaceutical</h2>
                    <p>A pharmacetical app made to simplify ease of buying  
                        medicines in just three clicks. The best in the industry. 
                        It is an is an American multinational pharmaceutical 
                        and biotechnology corporation headquartered on 42nd 
                        Street in Manhattan, New York City. Established in 
                        1849 in New York by two German immigrants Charles Pfizer 
                        and his cousin Charles F. Erhart.
                    </p>
                </div>
            </div>
            <div class="row c72">
                <div class="col-md-6 left">
                    <h2>Navbar</h2>
                    <p>
                        Simple and clean. We faced a challenge of designing a navbar 
                        that needs to be subtle and at the same time satisfactory 
                        to the user needs, so we relied on motion to enhance the 
                        experience and added color to highlight the functionality.
                    </p>
                </div>
                <div class="col-md-6 right">
                    <img alt='image' src="/assets/images_new/case-studies/scottedil/s10.png" >
                </div>
            </div>
        </div>
        <div class="c73 container2">
            <img alt='image' src="/assets/images_new/case-studies/scottedil/s101.svg" class="c731" >
            <img alt='image' src="/assets/images_new/case-studies/scottedil/s11.png" class="c732" >
        </div>
        <div class="c74">
            <div class="box">
                <h2>Dashboard</h2>
                <p>
                    From buying medicine in just three clicks and get it 
                    on your doorstep within a day Scott Edill has covered 
                    it all  We have designed the app keeping in mind the 
                    the necessacity of the healthcare situation and try to 
                    deliever medicine at your doorstep in just 12 hours.
                </p>
            </div>
        </div>
        <div class="c75">
            <img alt='image' src="/assets/images_new/case-studies/scottedil/slide1.png" >
        </div>
    </div>

    <div class="c8 container2">
        <div class="row c81">
            <div class="col-md-6 left">
                <h2>All App Screens</h2>
                <p>
                    From buying medicine in just three clicks and get 
                    it on your doorstep within a day Scott Edill has 
                    covered it all  We have designed the app keeping in 
                    mind the the necessacity of the healthcare situation 
                    and try to 
                    deliever medicine at your doorstep in just 12 hours.
                </p>
            </div>
        </div>
    </div>

    <div class="c9">
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s12.png" >
    </div>

</div>

<div class="nextBtn" style="width: 100%;padding-top: 7rem;padding-bottom: 14rem;font-size: 7rem;line-height: 8rem;text-align: center;">
    <span routerLink="/projects/hexabull" style="cursor: pointer;">
        Next
        <img alt='image' src="/assets/images_new/case-studies/arrow.svg" width="100" style="margin-left: 3rem; width: 100px !important;" >
    </span>
</div>