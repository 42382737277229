import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef
} from '@angular/core';
import {
    Router
} from '@angular/router';
import * as $ from 'jquery';
import { throttleTime } from 'rxjs/operators';
@Component({
    selector: 'app-custom-slider',
    templateUrl: './custom-slider.component.html',
    styleUrls: ['./custom-slider.component.scss']
})
export class CustomSliderComponent implements OnInit {

    @Output() currentSlide = new EventEmitter();

    @Input() colorText: string = '#000';
    @Input() sliderData: Array < any >= [];
    @Input() slideWidth: number = 60;
    @Input() slideWidthUnit: string = '%';
    @Input() activeSlide: number = 0;
    @Input() bgColor: string = 'transparent';
    @Input() bgLight: boolean= false;

    // showCursor: boolean = false;
    // leftCursor: boolean = false;
    // activeCursor: boolean = false;
    // rightCursor: boolean = false;

    index: number = 0;

    constructor(
        private router: Router,
    ) {}

    ngOnInit(): void {
        console.log(this.sliderData)
        for (let i = 0; i < this.sliderData.length; i++) {
            this.sliderData[i].slideIndex = i;
            this.sliderData[i].index = i;
        }

    }

    // @ViewChild("slides") slides: ElementRef;

    // ngAfterViewInit(): void {
    //     const slider = $(".sliderx");
    //     slider.on('wheel',throttleTime(300), ((e)=> {
    //         const btns1 = $(".slickCaseStudyBtn1")
    //         const btns2 = $(".slickCaseStudyBtn2")
    
    //         if (e.originalEvent.deltaY < 0) {
    //             if(btns1[0]){
    //                 e.preventDefault();
    //                 if(e.originalEvent.deltaY < -1)
    //                     this.slidersBtn(btns1)
    //             }
    //         } else {
    //             if(btns2[0]){
    //                 e.preventDefault()
    //                 if(e.originalEvent.deltaY > 0)
    //                 this.slidersBtn(btns2)
    //             }
    //         }
    //     }));
    // }
    // slideConfig = {
    //     "slidesToShow": 1,
    //     "slidesToScroll": 1,
    //     "speed": 200
    // };
    // timeLastCall: Date= new Date()

    // slidersBtn(btn: any){
    //     var d= new Date()
    //     if((d.getTime()-this.timeLastCall.getTime())>800){
    //         btn.click()
    //         this.timeLastCall= new Date()
    //     }else{
    //         return
    //     }
    // }


    // slickInit(e) {
    //     // console.log('slick initialized');
    // }

    // breakpoint(e) {
    //     // console.log('breakpoint');
    // }

    // afterChange(e) {
    //     // console.log('afterChange');
    //     this.index = e.currentSlide
    //     this.currentSlide.emit(this.sliderData[e.currentSlide])

    // }

    // beforeChange(e) {
    //     // console.log('beforeChange');
    // }
  
    // ngOnInit(): void {
    //   for(let i=0;i<this.sliderData.length;i++){
    //     this.sliderData[i].index= i;
    //   }
    // }
    ngAfterViewInit(): void {
      
      
      this.slider.nativeElement.addEventListener('mousemove',(e)=>{
          let el= document.getElementById('cs');
          if(el){
            el.style.top= (e.pageY)+'px';
            el.style.left= (e.pageX)+'px';
          }
  
    // if(e.screenX<(this.slider.nativeElement.offsetWidth*0.19)){
    //     this.leftCursor= true
    //     this.activeCursor= false
    //     this.rightCursor= false
    //   }else{
    //     if(e.screenX<(this.slider.nativeElement.offsetWidth*0.82)){
    //       this.activeCursor= true
    //       this.leftCursor= false
    //       this.rightCursor= false
    //     }else if(e.screenX<(this.slider.nativeElement.offsetWidth)){
    //       this.rightCursor= true
    //       this.leftCursor= false
    //       this.activeCursor= false
    //     }
    //   }
  })
  this.slider.nativeElement.addEventListener('touchmove',(e)=>{
    let el= document.getElementById('cs');
    if(el){
      el.style.top= (e.pageY)+'px';
      el.style.left= (e.pageX)+'px';
    }
    console.log(this.slider)
})
}

@ViewChild("slider") slider: ElementRef;


  public calculateSlidePosition(slide): string{
    var n=0;
    if(this.slideWidthUnit=='%'){
      n= (slide*(this.slideWidth+5)) + ((100-this.slideWidth)/2)
    }
    // var n= (slide*65)+20
    return n+this.slideWidthUnit
  }


    openCS(slide: any){
        // console.log(slide)
        this.router.navigate([`/projects/${slide.url}`])
    }
    public setActiveSlide(cs, slideNumber){
        if(slideNumber==this.activeSlide){
          this.openCaseStudy(cs)
          return
        }
        this.activeSlide= slideNumber
        for(let i=slideNumber,j=0;i<this.sliderData.length;i++,j++){
          this.sliderData[i].slideIndex=j;
        }
        for(let i=slideNumber-1,j=-1;i>=0;i--,j--){
          this.sliderData[i].slideIndex=j;
        }
        this.currentSlide.emit(cs)
      }
    
      public openCaseStudy(cs: any){
        this.router.navigate([`/projects/${cs.url}`])
      }
    
    
}