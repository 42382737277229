import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './components/error404/error404.component';
import { BlogDetailsComponent } from './pages/blogs-new/blog-details/blog-details.component';
import { BlogListComponent } from './pages/blogs-new/blog-list/blog-list.component';
import { BlogsNewComponent } from './pages/blogs-new/blogs-new.component';
import { CaseStudyHomeComponent } from './pages/case-study/case-study-home/case-study-home.component';
import { CaseStudyComponent } from './pages/case-study/case-study.component';
import { ChargebizzComponent } from './pages/case-study/chargebizz/chargebizz.component';
import { ContentTalksComponent } from './pages/case-study/content-talks/content-talks.component';
import { CrateCaffinateComponent } from './pages/case-study/crate-caffinate/crate-caffinate.component';
import { DizainaComponent } from './pages/case-study/dizaina/dizaina.component';
import { FinanceTreeComponent } from './pages/case-study/finance-tree/finance-tree.component';
import { FortuneProjectsComponent } from './pages/case-study/fortune-projects/fortune-projects.component';
import { HexabullComponent } from './pages/case-study/hexabull/hexabull.component';
import { HmaComponent } from './pages/case-study/hma/hma.component';
import { IptseComponent } from './pages/case-study/iptse/iptse.component';
import { QnvertComponent } from './pages/case-study/qnvert/qnvert.component';
import { SanronComponent } from './pages/case-study/sanron/sanron.component';
import { ScottedilComponent } from './pages/case-study/scottedil/scottedil.component';
import { ContactUsNewComponent } from './pages/contact-us-new/contact-us-new.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
// import { ContactComponent } from './pages/contact/contact.component';
// import { AboutUsComponent } from './pages/about-us/about-us.component';
// import { ApproachComponent } from './pages/approach/approach.component';
// import { HomeComponent } from './pages/home/home.component';
// import { NewsDetailsComponent } from './pages/news-details/news-details.component';
// import { NewsComponent } from './pages/news/news.component';
// import { PortfolioComponent } from './pages/portfolio/portfolio.component';
// import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
// import { ServicesComponent } from './pages/services/services.component';
// import { TeamsComponent } from './pages/teams/teams.component';
import { CultureComponent } from './pages/culture/culture.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeNewComponent } from './pages/home-new/home-new.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoKilobyteComponent } from './pages/logo-kilobyte/logo-kilobyte.component';
import { ProjectHomeComponent } from './pages/projects/project-home/project-home.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ServicesNewComponent } from './pages/services-new/services-new.component';
import { TestComponent } from './test/test.component';
import { AuthGuard } from './utils/guard/authguard/auth.gaurd';
import { ScribbleTComponent } from './pages/case-study/scribble-t/scribble-t.component';
import { ReferralComponent } from './pages/referral/referral.component';


const routes: Routes = [
  // {
  //   path: 'home',
  //   component: HomeComponent
  // },
  // {
  //   path: 'test',
  //   component: TestCompComponent
  // },
  {
    path: '',
    component: HomeNewComponent,
    data: {
      title: 'Kilobyte Technologies',
      url: 'https://kilobytetech.com',
      description: 'Painting Vision, Nurturing Culture and Helping people grow together. We build products that your customers love. We craft digital products and experiences that start, scale, and empower your organization.'
    }
  },
  // {
  //   path: 'about-us',
  //   component: AboutUsComponent
  // },
  // {
  //   path: 'services',
  //   component: ServicesComponent
  // },
  {
    path: 'services',
    component: ServicesNewComponent,
    data: {
      title: 'Kilobyte - Services',
      url: 'https://kilobytetech.com/services',
      description: ''
    }
  },
  {
    path: 'logo',
    component: LogoKilobyteComponent,
    data: {
      title: 'Kilobyte - Services',
      url: 'https://kilobytetech.com/services',
      description: ''
    }
  },
  {
    path: 'culture',
    component: CultureComponent,
    data: {
      title: 'Kilobyte - Culture',
      url: 'https://kilobytetech.com/culture',
      description: ''
    }
  },
  // {
  //   path: 'people',
  //   component: TeamsComponent
  // },
  // {
  //   path: 'approach',
  //   component: ApproachComponent
  // },
  // {
  //   path: 'contact-us',
  //   component: ContactComponent
  // },
  {
    path: 'contact-us',
    component: ContactUsNewComponent,
    data: {
      title: 'Kilobyte - Contact Us',
      url: 'https://kilobytetech.com/contact-us',
      description: ''
    }
  },
  {
    path: 'referral',
    component: ReferralComponent,
    data: {
      title: 'Kilobyte - BNI Marketing/Media Power Team',
      url: 'https://kilobytetech.com/referral',
      description: ''
    }
  },

  // {
  //   path: 'portfolio',
  //   component: PortfolioComponent
  // },
  {
    path: 'projects',
    component: ProjectsComponent,
    children: [
      { 
        path: '', 
        component: ProjectHomeComponent,
        data:{
          title: 'Kilobyte - Projects',
          url: 'https://kilobytetech.com/projects',
          description: ''
        } 
      },
      { 
        path: 'qnvert', 
        component: QnvertComponent,
        data:{
          title: 'Kilobyte - Qnvert',
          url: 'https://kilobytetech.com/projects/qnvert',
          description: ''
        } 
      },
      { 
        path: 'chargebizz', 
        component: ChargebizzComponent,
        data:{
          title: 'Kilobyte - Chargebizz',
          url: 'https://kilobytetech.com/projects/chargebizz',
          description: ''
        } 
      },
      { 
        path: 'scottedil', 
        component: ScottedilComponent,
        data:{
          title: 'Kilobyte - Scottedil',
          url: 'https://kilobytetech.com/projects/scottedil',
          description: ''
        } 
      },
      { 
        path: 'hexabull', 
        component: HexabullComponent,
        data:{
          title: 'Kilobyte - Hexabull',
          url: 'https://kilobytetech.com/projects/hexabull',
          description: ''
        } 
      },
      { 
        path: 'iptse', 
        component: IptseComponent,
        data:{
          title: 'Kilobyte - IPTSE',
          url: 'https://kilobytetech.com/projects/iptse',
          description: ''
        } 
      },
      { 
        path: 'fortune-projects', 
        component: FortuneProjectsComponent,
        data:{
          title: 'Kilobyte - Fortune Projects',
          url: 'https://kilobytetech.com/projects/fortune-projects',
          description: ''
        } 
      },
      { 
        path: 'hma', 
        component: HmaComponent,
        data:{
          title: 'Kilobyte - HMA',
          url: 'https://kilobytetech.com/projects/hma',
          description: ''
        } 
      },
      { 
        path: 'crate-caffinate', 
        component: CrateCaffinateComponent,
        data:{
          title: 'Kilobyte - Crate Caffinate',
          url: 'https://kilobytetech.com/projects/crate-caffinate',
          description: ''
        } 
      },
      { 
        path: 'dizaina', 
        component: DizainaComponent,
        data:{
          title: 'Kilobyte - Dizania',
          url: 'https://kilobytetech.com/projects/dizaina',
          description: ''
        } 
      },
      { 
        path: 'sanron', 
        component: SanronComponent,
        data:{
          title: 'Kilobyte - Sanron',
          url: 'https://kilobytetech.com/projects/sanron',
          description: ''
        } 
      },
      { 
        path: 'finance-tree', 
        component: FinanceTreeComponent,
        data:{
          title: 'Kilobyte - Finance Tree',
          url: 'https://kilobytetech.com/projects/finance-tree',
          description: ''
        } 
      },
      { 
        path: 'content-talks', 
        component: ContentTalksComponent,
        data:{
          title: 'Kilobyte - Content Talks',
          url: 'https://kilobytetech.com/projects/content-talks',
          description: ''
        } 
      },
      { 
        path: 'scribble-t', 
        component: ScribbleTComponent,
        data:{
          title: 'Kilobyte - Scribble T',
          url: 'https://kilobytetech.com/projects/scribble-t',
          description: ''
        } 
      }
    ]
  },
  // {
  //   path: 'blogs',
  //   component: NewsComponent
  // },
  {
    path: 'blogs',
    component: BlogsNewComponent,
    children: [
      { 
        path: '', 
        component: BlogListComponent,
        data: {
          title: 'Kilobyte - Blogs',
          url: 'https://kilobytetech.com/blogs',
          description: ''
        }
      },
      { 
        path: ':name', 
        component: BlogDetailsComponent,
        data: {
          title: 'Kilobyte - Blogs',
          url: 'https://kilobytetech.com/blogs',
          description: ''
        }
      }
    ]
  },
  {
    path: 'test33',
    component: TestComponent
  },

  // {
  //   path: 'blogs/:name',
  //   component: NewsDetailsComponent
  // },
  // {
  //   path: 'portfolio/:name',
  //   component: NewsDetailsComponent
  // },
  // {
  //   path: 'services/:id',
  //   component: ServiceDetailsComponent
  // },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'tools',
    loadChildren: () => import('./tools/tools.module').then(mod => mod.ToolsModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fb',
    loadChildren: () => import('./tools/firebase/firebase.module').then(mod => mod.FirebaseModule),
  },

  // *****************************************************************************************************
  // *****************************************************************************************************
  // mislaneous routes ---- start
  // *****************************************************************************************************
  // *****************************************************************************************************

  {
    path: 'business-card-scanner',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'header',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'jobs',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'tag/saas',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'google-maps',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: '2009/09',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'build-good-responsive-design',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'portfolio/case-study-biotrips',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: '2018/07/09/hello-world',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'portfolio/case-study-healthypal',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: '2017/05',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'category/template-2',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'category/media-2',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'web-development',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'portfolio/case-study-content-talks',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'portfolio/case-study-impressions',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'home',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'portfolio/portfolio-center-slider',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'portfolio-masonry-4-columns',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'career',
    redirectTo: '/',
    pathMatch: 'full'
  },

  // *****************************************************************************************************
  // *****************************************************************************************************
  // mislaneous routes ---- end
  // *****************************************************************************************************
  // *****************************************************************************************************


  {
    path: '**',
    component: Error404Component
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
