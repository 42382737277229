import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HomeComponent } from './pages/home/home.component';
// import { AboutUsComponent } from './pages/about-us/about-us.component';
// import { ServicesComponent } from './pages/services/services.component';
// import { TeamsComponent } from './pages/teams/teams.component';
// import { ApproachComponent } from './pages/approach/approach.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
// import { PortfolioComponent } from './pages/portfolio/portfolio.component';
// import { PortfolioDetailsComponent } from './pages/portfolio-details/portfolio-details.component';
// import { NewsComponent } from './pages/news/news.component';
// import { NewsDetailsComponent } from './pages/news-details/news-details.component';
// import { ServiceDetailsComponent } from './pages/service-details/service-details.component';
// import { TestCompComponent } from './pages/test-comp/test-comp.component';
// import { ContactComponent } from './pages/contact/contact.component';
// import { AddressSectionComponent } from './components/address-section/address-section.component';
// import { ContactFooterComponent } from './components/contact-footer/contact-footer.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SharedModule } from './utils/shared/shared.module';
import { LoginComponent } from './pages/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptorService } from './utils/services/httpInterceptor/http-interceptor.service';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SecondaryFooterComponent } from './components/secondary-footer/secondary-footer.component';
import { SecondaryHeroPartComponent } from './components/secondary-hero-part/secondary-hero-part.component';
import { SplitSectionComponent } from './components/split-section/split-section.component';
import { PillarsElementComponent } from './components/pillars-element/pillars-element.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HomeNewComponent } from './pages/home-new/home-new.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { DragScrollModule } from 'ngx-drag-scroll';
import { CustomSliderComponent } from './pages/home-new/custom-slider/custom-slider.component';
import { FooterNewComponent } from './pages/home-new/footer-new/footer-new.component';
import { ContactUsNewComponent } from './pages/contact-us-new/contact-us-new.component';
import { CommonModule, ViewportScroller } from '@angular/common';
import { QnvertComponent } from './pages/case-study/qnvert/qnvert.component';
import { CaseStudyComponent } from './pages/case-study/case-study.component';
import { ChargebizzComponent } from './pages/case-study/chargebizz/chargebizz.component';
import { ScottedilComponent } from './pages/case-study/scottedil/scottedil.component';
import { HexabullComponent } from './pages/case-study/hexabull/hexabull.component';
import { ServicesNewComponent } from './pages/services-new/services-new.component';
import { CultureComponent } from './pages/culture/culture.component';
import { BlogsNewComponent } from './pages/blogs-new/blogs-new.component';
import { BlogDetailsComponent } from './pages/blogs-new/blog-details/blog-details.component';
import { BlogListComponent } from './pages/blogs-new/blog-list/blog-list.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { CaseStudyHomeComponent } from './pages/case-study/case-study-home/case-study-home.component';
import { IptseComponent } from './pages/case-study/iptse/iptse.component';
import { FortuneProjectsComponent } from './pages/case-study/fortune-projects/fortune-projects.component';
import { HmaComponent } from './pages/case-study/hma/hma.component';
import { CrateCaffinateComponent } from './pages/case-study/crate-caffinate/crate-caffinate.component';
import { DizainaComponent } from './pages/case-study/dizaina/dizaina.component';
import { ProjectHomeComponent } from './pages/projects/project-home/project-home.component';
import { SanronComponent } from './pages/case-study/sanron/sanron.component';
import { FinanceTreeComponent } from './pages/case-study/finance-tree/finance-tree.component';
import { ContentTalksComponent } from './pages/case-study/content-talks/content-talks.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Router, RouteReuseStrategy, Scroll } from '@angular/router';
import { CustomReuseStrategy } from './custom.reuse.strategy';
import { HeaderNewComponent } from './pages/home-new/header-new/header-new.component';
import { Error404Component } from './components/error404/error404.component';
import { ScribbleTComponent } from './pages/case-study/scribble-t/scribble-t.component';
import { ReferralComponent } from './pages/referral/referral.component';
@NgModule({
  declarations: [
    
    Error404Component,
    SideMenuComponent,
    HeaderNewComponent,
    AppComponent,
    // HomeComponent,
    // AboutUsComponent,
    // ServicesComponent,
    // TeamsComponent,
    // ApproachComponent,
    // ContactUsComponent,
    // PortfolioComponent,
    // PortfolioDetailsComponent,
    HeaderComponent,
    FooterComponent,
    // NewsComponent,
    // NewsDetailsComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SecondaryFooterComponent,
    // ContactComponent,
    SecondaryHeroPartComponent,
    SplitSectionComponent,
    // AddressSectionComponent,
    // ContactFooterComponent,
    PillarsElementComponent,
    // ServiceDetailsComponent,
    HomeNewComponent,
    CustomSliderComponent,
    FooterNewComponent,
    ContactUsNewComponent,
    QnvertComponent,
    CaseStudyComponent,
    ChargebizzComponent,
    ScottedilComponent,
    HexabullComponent,
    ServicesNewComponent,
    CultureComponent,
    BlogsNewComponent,
    BlogDetailsComponent,
    BlogListComponent,
    ProjectsComponent,
    // TestCompComponent,
    CaseStudyHomeComponent,
    IptseComponent,
    FortuneProjectsComponent,
    HmaComponent,
    CrateCaffinateComponent,
    DizainaComponent,
    ProjectHomeComponent,
    SanronComponent,
    FinanceTreeComponent,
    ContentTalksComponent,
    ScribbleTComponent,
    ReferralComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    ScrollToModule.forRoot(),
    DragScrollModule,
    SlickCarouselModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },
        
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class AppModule { 
  
}
